import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import { Container, Typography, TextField, Button, Box } from "@mui/material"

const Contact = () => {
  return (
    <Layout>
      <Seo
        title="Contact"
        description="Looking for web development, web design or SEO services? Let me help to bring your business online."
      />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
          mb: "3rem",
        }}
      >
        <Container maxWidth="sm" sx={{ mt: "1rem" }}>
          <form
            autoComplete="off"
            action="https://formspree.io/f/mknpvknw"
            method="POST"
          >
            <Box sx={{ my: 3 }}>
              <Typography variant="h1" align="center" gutterBottom>
                Get in touch
              </Typography>
              <Typography variant="body1" align="center">
                Are you looking for web development, web design or SEO services?
                Let me help to bring your business online.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
              }}
            >
              <TextField
                label="Name"
                name="name"
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                required
              />
              <TextField
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                margin="normal"
                fullWidth
                required
              />
              <TextField
                label="Message"
                name="message"
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                fullWidth
                required
                sx={{ mb: "1.5rem" }}
              />
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Layout>
  )
}

export default Contact
